body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.list-unstyled {
    list-style-type: none;
    padding: 0;
}

.container:not(.footer-collapse__container) {
    max-width: 1200px;
    margin-bottom: 15rem;
}

.footer-collapse__container {
    max-height: 200px;
}

ul.list-styled>li.default:before {
    color: #342b60;
}

.add-title h4,
.add-title .display-2 {
    width: auto;
    display: flex;
}

.add-title .display-2 {
    margin-bottom: 1rem;
}

.add-title .col:last-child h4 {
    justify-content: flex-end;
}

.btn-link a,
.btn-link a:hover {
    color: inherit;
    background-color: inherit;
}

.login-btn {
    color: #fff;
}

.main-cardContent {
    padding: 90px 4.5rem;
}

.more-actions-buttons {
    display: none;
    position: absolute;
    right: 14px;
    opacity: 0;
    transform: translateX(20px);
    z-index: 1;
    white-space: nowrap;
}

.advanced-table-row:hover .more-actions-buttons {
    display: inline-flex;
    gap: 8px;
    padding-left: 10px;
    background: #ededf2;
    animation: fadeInRight 0.2s forwards;
}

.advanced-table-row:hover .more-actions-buttons:before {
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 1rem;
    background: #ededf2;
    background: linear-gradient(to right, transparent 0%, #ededf2 100%);
    content: "";
}

.alert-warning {
    z-index: 1101;
}

.tt-level1__nav {
    justify-content: space-between;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@media screen and (max-width: 768px) {
    .main-cardContent {
        padding: 3rem 2rem;
    }

}