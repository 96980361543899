.background {
    background: linear-gradient(90deg, rgba(228, 6, 126, 1) 0%, rgba(52, 43, 96, 1) 100%);
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
}

.container.background-container {
    width: 100%;
    max-width: unset;
    min-height: auto;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
    margin-bottom: unset;
}

.background .img-fluid {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.column-welcome,
.column-login {
    min-height: 410px;
}

.column-login {
    padding: 4rem 0;
    background-color: #fff;
}

.column-welcome {
    display: flex;
    justify-content: flex-end;
}

.logo-padding {
    padding: 100px 0px;
}

.logo {
    position: absolute;
    top: -98px;
    left: 50%;
    transform: translateX(-65%);
}

.login-formGroup {
    width: 340px;
}

.column-welcome-sub1,
.column-welcome-sub2,
.column-welcome-sub3 {
    background-color: #fff;
}

.column-welcome-sub1 {
    height: 50%;
}

.column-welcome-sub2 {
    height: 80%;
}

@media screen and (max-width: 991px) {
    .background {
        height: 100%;
        padding: 1rem 0;
    }
    .background-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .column-login {
        min-height: unset;
        padding: 1rem 2rem;
    }

    .column-welcome {
        min-height: unset;
        justify-content: center;
        padding: 1.5rem 2rem 0 2rem;
    }

    .logo-padding {
        padding: unset;
    }

    .logo {
        position: absolute;
        top: unset;
        left: unset;
        transform: unset;
        bottom: 0px;
    }
}

@media screen and (max-width: 576px) {
    .login-formGroup {
        width: 100%;
    }

    .column-welcome-sub3 .display-2 {
        font-size: 2.5rem;
    }
}