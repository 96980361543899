.rounded-pill button {
    border-radius: 20px;
}

.rounded-pill button.btn-secondary {
    color: #fff;
    border-color: #342b60;
    background-color: #342b60;
}

.rounded-pill button.tt-btn-outline-secondary {
    color: #342b60;
    border-color: #342b60;
}

.rounded-pill button.tt-btn-outline-secondary:hover {
    background-color: #dadae4;
}

.add-status {
    width: fit-content;
    margin-left: 20px;
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid #DADAE4;
}

.add-status.status-tag:before {
    left: 0.55rem;
}